/* eslint-disable @next/next/no-img-element */
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SearchIcon from '~/assets/svgs/search.svg';
import Button from '~/components/Button';
import Container from '~/components/Container';
import { MegaMenuType } from '~/constants/enum';
import { megaMenuAtom } from '~/services/megaMenu';
import { recentKeywordState } from '~/services/recent-search/recent-search.atom';
import storeConfigAtom from '~/services/store-config/store-config.atom';
import { getTheme } from '~/utils/theme';
import SearchFilter from '../SearchFilter';
import styles from './Header.module.scss';
import MobileHeaderTwo from './MobileHeaderTwo';
import useClient from '~/hooks/useClient';
import Image from 'next/legacy/image';
import { UserAtom } from '~/services/user';
import { CartAtom } from '~/services/cart';
import Alert from '~/components/Alert';
import { NodesResponse } from '~/data/home/interface';

const MobileHeader = dynamic(() => import('./MobileHeader'), { ssr: false });

const TopHeader = dynamic(() => import('~/components/TopHeader'), {
    ssr: false,
    loading(loadingProps) {
        return null; // or return a loading component
    }
});

const UserButton = dynamic(() => import('~/components/UserButton'), {
    ssr: false,
    loading(loadingProps) {
        return null; // or return a loading component
    }
});

const WishButton = dynamic(() => import('~/components/WishButton'), {
    ssr: false,
    loading(loadingProps) {
        return null; // or return a loading component
    }
});

const CartButton = dynamic(() => import('~/components/CartButton'), {
    ssr: false,
    loading(loadingProps) {
        return null; // or return a loading component
    }
});
const Navigation: any = dynamic(async () => {
    try {
        return (await require(`~/theme/${getTheme()}/sections/Navigation`)).default;
    } catch (error) {
        return (await require(`~/sections/Navigation`)).default;
    }
},
    { ssr: true, loading: () => null });

interface HeaderProps {
    menu?: NodesResponse[];
    storeConfig?: {
        frontend_config_logo: string;
    }
}

const Header: FC<HeaderProps> = ({ menu, storeConfig: storeConfigServerSide }) => {
    const [openSearch, setOpenSearch] = useState(false);
    const refHeader = useRef<any>();
    const recoilRecentKeywords = useRecoilValue(recentKeywordState);

    const [openMenu, setOpenMenu] = useState(false);
    const storeConfig = useRecoilValue(storeConfigAtom.store);
    const [recentKeywords, setRecentKeywords] = useState<string[]>();
    const currentUser = useRecoilValue(UserAtom.currentUser);
    const Cart = useRecoilValue(CartAtom.cartSelector);

    const handleOpenSearch = () => {
        setTimeout(() => {
            setOpenSearch(!openSearch);
        }, 0);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setRecentKeywords(recoilRecentKeywords);
        }
    }, [recoilRecentKeywords]);

    return (
        <>
            {<TopHeader />}

            <div className={styles.header} ref={refHeader}>
                <Alert />
                <Container className="flex items-center justify-around relative h-full" rootClassName="h-full">
                    <div className="flex items-center gap-1">
                        <Button
                            className="flex lg:!hidden !text-white"
                            prefixIcon="menu1"
                            shape="square"
                            type="borderless"
                            onClick={() => setOpenMenu(true)}
                            aria-label="Menu"
                        />
                        <Link href="/" className="flex items-center">
                            {(storeConfigServerSide ?? storeConfig)?.frontend_config_logo && (
                                <Image
                                    src={(storeConfigServerSide ?? storeConfig)?.frontend_config_logo || ''}
                                    alt="Logo"
                                    className="w-auto h-[40px] object-contain object-left"
                                    width={180}
                                    height={40}
                                    loading="eager"
                                    loader={({ width, src }) => {
                                        return src;
                                    }}
                                />
                            )}
                        </Link>
                    </div>

                    <div className="flex-1 w-[500px] gap-[56px] text-center">
                        <div className="hidden md:flex flex-1 justify-center  ">
                            <Navigation menu={menu} />
                        </div>
                    </div>

                    <div
                        className="flex min-w-[180px] justify-end "
                        style={{
                            pointerEvents: currentUser?.loading || Cart?.loading ? 'none' : 'auto'
                        }}
                    >
                        <Button
                            prefixIcon={<SearchIcon width={24} height={24} alt="Search" />}
                            shape="square"
                            type="borderless"
                            onClick={handleOpenSearch}
                            aria-label="Search"
                        />
                        <UserButton />
                        <WishButton />
                        <CartButton />
                    </div>

                    {/* <div
            onMouseLeave={handleCloseDropdown}
          >
            {!!dataSubmenu?.submenu && (
              <div
                className={classNames(stylesMenuDropDow.menu_dropdown, "dropdown-animation", {
                  "dropdown-animation-show": true,
                  [stylesMenuDropDow.fullWidth]: dataSubmenu.type === MegaMenuType.Wrapper,
                  ["w-[150%]"]: dataSubmenu.type !== MegaMenuType.Wrapper,
                })}
                style={{
                  top: 72
                }}
              >
                <div className="flex flex-col gap-2 relative" >
                  {dataSubmenu.submenu.map((submenuItem: any, index: number) => {
                    return (
                      <Fragment key={submenuItem.id || v4()}>
                        <MenuDropdown data={submenuItem} />
                        {index < dataSubmenu.submenu.length - 1 && <Divider />}

                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div> */}
                </Container>
                <SearchFilter
                    recentKeywords={recentKeywords?.slice(0, 5)}
                    openSearch={openSearch}
                    onCloseSearch={() => setOpenSearch(false)}
                />
                <MobileHeaderTwo open={openMenu} onClose={() => setOpenMenu(false)} />
            </div>
        </>
    );
};

export default Header;
